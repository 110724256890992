import {useMarkdownConverterTempV2} from '#imports';

const {mdToHtml} = useMarkdownConverterTempV2();

export function useMarkdownToHtml() {
    function markdownToHtml(markdown?: string): string {
        if (typeof markdown !== 'string') return '';
        const escaped = markdown.replace(/</gi, '&lt;').replace(/>/gi, '&gt;');
        return mdToHtml(escaped);
    }

    return {
        markdownToHtml,
    };
}
