import {useNuxtApp} from '#imports';

interface Rule {
    regex: RegExp;
    replacer: ((substring: string, ...args: string[]) => string) | string;
}

export function useMarkdownConverterTempV2() {
    const nuxtApp = useNuxtApp();

    // Markdown produced here has to be compatible with our MarkdownLight taglib
    function htmlToMd(input: string): string {
        const rules: {[index: string]: Rule} = {
            nbsp: {regex: /&nbsp;/gm, replacer: ' '},
            blockEmpty: {regex: /<(p|div|b|strong)>( )*<\/(p|div|b|strong|em|i)>/gim, replacer: ''},
            blockEmptyWithBrInside: {regex: /(<(p|div|strong|b)>)+?<br>(<\/(p|div|strong|b|em|i)>)+/gim, replacer: '<br>'},
            blockWithUlInside: {regex: /<(p|div)>(<ul>.*?<\/ul>)<\/(p|div)>/gim, replacer: (_: string, $1: string, $2) => `${$2}`},
            blockOpeningTags: {regex: /<(p|div)>/gim, replacer: ''},
            blockClosingTags: {regex: /<\/(p|div)>/gim, replacer: '<br>'},
            boldOpening: {regex: /<(strong|b)>(\s*)/gim, replacer: (_: string, $1: string, $2: string) => `${$2} **`},
            boldClosing: {regex: /(\s*)<\/(strong|b)>/gim, replacer: (_: string, $1: string) => `** ${$1}`},
            titleAndListMerge: {regex: /<\/h1>([^\S\r\n\f]*(<div>)?[^\S\r\n\f]*<ul>)/gim, replacer: (_: string, $1: string) => `\n\n${$1}`},
            ul: {regex: /<\/?ul>/gim, replacer: ''},
            liOpeningTag: {regex: /<li>/gim, replacer: '* '},
            liClosingTag: {regex: /(<br>)?<\/li>/gim, replacer: '\n'},
            h1OpeningTag: {regex: /<h1(.*?)>/gim, replacer: '# '},
            h1ClosingTag: {regex: /(<br>)?<\/h1>/gim, replacer: '\n'},
            span: {regex: /<span(.*?)>(.*?)<\/span>/gim, replacer: (_: string, $1: string, $2: string) => `${$2}`},
            br: {regex: /<br ?\/?>/gm, replacer: '\n'},
            amp: {regex: /&amp;/gm, replacer: '&'},
            gt: {regex: /&gt;/gm, replacer: '>'},
            lt: {regex: /&lt;/gm, replacer: '<'},
            unknownOpeningTags: {regex: /<(.*?)>/gim, replacer: ''},
            unknownClosingTags: {regex: /<\/(.*?)>/gim, replacer: ''},
        };
        return applyRules(rules, input);
    }

    function mdToHtml(input: string): string {
        const rules: {[index: string]: Rule} = {
            CRcharacter: {regex: /\r/gm, replacer: ''},
            bold: {
                regex: /(( |^)\*\*|( |^)__)(.*?)(\*\*( |$)|__( |$))/gim,
                replacer: (_: string, $1: string, $2: string, $3: string, $content: string) => `<b>${$content}</b>`,
            },
            div: {regex: /^([^#*\n].*)(\n|$)/gim, replacer: (_: string, $1: string) => `<div>${$1}</div>\n`},
            titleListSplit: {regex: /(#.*?)\n(\*)/gim, replacer: (_: string, $1: string, $2: string) => `${$1}\n\n${$2}`},
            listTitleSplit: {regex: /(\*.*?)\n(.*?#)/gim, replacer: (_: string, $1: string, $2: string) => `${$1}\n\n${$2}`},
            h1: {
                regex: /(\n([^\S\r\n\f])*#|^([^\S\r\n\f])*#) (.*?)\n/gim,
                replacer: (_: string, $1: string, $2: string, $3: string, $4: string) => `<h1>${$4.trim()}</h1>`,
            },
            listSplit: {regex: /(\*.*?\n)(\n*)(\n\*)/gim, replacer: (_: string, $1: string, $2: string, $3: string) => `${$1}<br>${$3}`},
            list: {regex: /(^\*|\n\*) (.*?)\n/gim, replacer: (_: string, $1: string, $2: string) => `<ul><li>${$2.trim()}</li></ul>`},
            listMerge: {regex: /<\/ul>[^\S\r\n\f]*<ul>/gim, replacer: ''},
            divLineBreak: {regex: /<\/div>\n(\n*)(<div>|$)/gim, replacer: (_: string, $1: string, $2: string) => `</div>${$1}${$2}`},
            lineBreak: {regex: /\n/gim, replacer: '<br>'},
        };
        return applyRules(rules, _sanitize(input));
    }

    function mdToText(input: string): string {
        const rules: {[index: string]: Rule} = {
            CRcharacter: {regex: /\r/gm, replacer: ''},
            boldAsterisk: {
                regex: /( |^)\*\*(.*?)\*\*( |$)/gim,
                replacer: (_: string, $1: string, $content: string, $2: string) => `${$1}${$content}${$2}`,
            },
            boldUnderscore: {
                regex: /( |^)__(.*?)__( |$)/gim,
                replacer: (_: string, $1: string, $content: string, $2: string) => `${$1}${$content}${$2}`,
            },
            div: {regex: /^([^#*\n].*)(\n)/gim, replacer: (_: string, $1: string) => `${$1}\n`},
            titleListSplit: {
                regex: /(#.*?)\n(\*)/gim,
                replacer: (_: string, $1: string, $2: string) => `${$1}\n\n${$2}`,
            },
            listTitleSplit: {
                regex: /(\*.*?)\n(.*?#)/gim,
                replacer: (_: string, $1: string, $2: string) => `${$1}\n\n${$2}`,
            },
            h1: {
                regex: /(\n([^\S\r\n\f])*#|^([^\S\r\n\f])*#) (.*?)\n/gim,
                replacer: (_: string, $1: string, $2: string, $3: string, $4: string) => `${$1 && $1.includes('\n') ? '\n' : ''}${$4.trim()}\n\n`,
            },
            listSplit: {
                regex: /(\*.*?\n)(\n*)(\n\*)/gim,
                replacer: (_: string, $1: string, $2: string, $3: string) => `${$1}\n${$3}`,
            },
            list: {
                regex: /(^\*|\n\*) (.*?)\n/gim,
                replacer: (_: string, $1: string, $2: string) => `${$2.trim()}\n`,
            },
        };

        const purifiedInput = _sanitize(input);

        /* Called twice because negative lookbehind don't work on Safari and so repeated pattern such as
         * "**bold** **bold** **bold**" cannot be captured in one replacement because of the single intermediate
         * space between them.
         * Thus they are first replaced as "bold **bold** bold" and then "bold bold bold".
         */
        return applyRules(rules, applyRules(rules, purifiedInput));
    }

    function applyRules(rules: {[p: string]: Rule}, input: string): string {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore https://github.com/microsoft/TypeScript/issues/22378
        return Object.keys(rules).reduce((acc, key) => acc.replace(rules[key].regex, rules[key].replacer), input);
    }

    function _sanitize(dirty: string): string {
        // sanitizeHtml is provided by @malt/nuxt-utils-module
        if (!nuxtApp.$sanitizeHtml) {
            throw new Error('useMarkdownConverterTempV2: sanitize-html plugin is not activated!');
        }

        const sanitize = nuxtApp.$sanitizeHtml as (dirty: string) => string;

        return sanitize(dirty);
    }

    return {
        htmlToMd,
        mdToHtml,
        mdToText,
    };
}
