<template>
    <div data-testid="profile-experience-item-date">
        <span>{{ datesAsString }}</span>
        <span v-if="isVisibleRange">
            {{ rangeAsString }}
        </span>
    </div>
</template>

<script lang="ts" setup>
    import {useLocale, useTranslation} from '#imports';
    import {intervalToDuration, type Interval} from 'date-fns';
    import {computed} from 'vue';
    import {useDateFormatterFromDate} from '~/composables/useDateFormatter';

    const {locale} = useLocale();
    const {t} = useTranslation();

    const props = defineProps<{
        startDate: Date;
        endDate?: Date;
        current: boolean;
    }>();

    const datesAsString = computed(() => {
        let date = formatDate(props.startDate);
        if (props.current) {
            date += ' - ' + t('profile.xp.reco.humanize.time.today');
        } else if (props.endDate) {
            date += ' - ' + formatDate(props.endDate);
        }
        return date;
    });

    const experienceRange = computed(() => {
        const interval: Interval = {
            start: props.startDate,
            end: props.current ? new Date() : props.endDate ? props.endDate : new Date(),
        };

        const duration = intervalToDuration(interval);
        const years = duration.years || 0;
        let months = duration.months || 0;

        if (duration.days && duration.days > 15) {
            months = months + 1;
        }

        return {years, months};
    });
    const isVisibleRange = computed(() => !!experienceRange.value.years || !!experienceRange.value.months);
    const rangeAsString = computed(() => {
        const years = experienceRange.value.years ? t('profile.experience.period.years', {nbOfYears: experienceRange.value.years}) : '';
        const months = experienceRange.value.months ? t('profile.experience.period.months', {nbOfMonths: experienceRange.value.months}) : '';
        const optAnd = !!years && !!months ? ` ${t('general.and')} ` : '';

        return ` (${years}${optAnd}${months})`;
    });

    function formatDate(date: Date): string {
        return useDateFormatterFromDate(date, locale.value, {year: 'numeric', month: 'long'});
    }
</script>
